import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { useField } from 'react-final-form';
import useRequest from '../../hooks/useRequest';
import { fetchImages } from '../../services/pexels';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import { SuggestionImageCarouselField } from './SuggestionImageCarousel';
import Suggestion from './Suggestion.interface';

const SuggestedImages = ({ name, editable, suggestion }: {
  name: string,
  editable: boolean,
  suggestion: Suggestion
}) => {
  const field = useField(name, {});

  const imagesRequest = useRequest(
    async () => {
      if (!suggestion.keywords) return null;
      if (field.input.value) return null;

      const urls = await Promise.all(
        suggestion.keywords.map((query: string) => {
          return fetchImages({ query })
            .then(r => r.items[0].src.original);
        }),
      );

      field.input.onChange(urls);

      return urls;
    },
    [suggestion, field.input.value],
  );

  return (
    <Skeleton isLoaded={!imagesRequest.loading} borderRadius="2em">
      <ErrorAlert error={imagesRequest.error} />
      <SuggestionImageCarouselField name={name} editable={editable} />
    </Skeleton>
  );
};

export default SuggestedImages;
